import React, { useState, useRef } from "react";
import { Button, Form, Input } from "antd";
import IntlMessages from "util/IntlMessages";
import { useDispatch } from "react-redux";
import { confirmSetPassword } from "../appRedux/actions/Auth";
import { useLocation } from "react-router-dom";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";

const FormItem = Form.Item;

const SetPassword = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParamsString = location.search.substring(1),
    searchParams = new URLSearchParams(queryParamsString);
  const token = searchParams.get("token");

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const passwordRef = useRef(null);

  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const confirmPasswordRef = useRef(null);

  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
    if (passwordRef.current) {
      const input = passwordRef.current.input;
      input.focus();
      input.setSelectionRange(input.value.length, input.value.length);
    }
  };

  const handlePasswordChange = (e) => {
    setPasswordValue(e.target.value);
  };

  const handleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
    if (confirmPasswordRef.current) {
      const input = confirmPasswordRef.current.input;
      input.focus();
      input.setSelectionRange(input.value.length, input.value.length);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPasswordValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", { ...values, token });
        dispatch(confirmSetPassword({ ...values, token }));
      }
    });
  };

  const compareToFirstPassword = (rule, value, callback) => {
    const form = props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    const form = props.form;
    if (value && props.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  const { getFieldDecorator } = props.form;
  var bg = require("assets/images/portal_bg.png");

  return (
    <div
      className="gx-app-login-wrap"
      style={{ backgroundImage: "url(" + bg + ")" }}
    >
      <div className="gx-login-container">
        <div className="gx-login-content">
          <div className="gx-login-header">
            <img
              src={require("assets/images/finbit.png")}
              style={{
                height: "auto",
                width: "auto",
                "max-width": 350,
                "max-height": 200,
                marginLeft: 40,
              }}
              alt="FINBIT Admin"
              title="FINBIT"
            />
          </div>
          <div className="gx-mb-4">
            <h3>Set Your Password </h3>
          </div>

          <Form onSubmit={handleSubmit} className="gx-login-form gx-form-row0">
            <FormItem>
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Password is required.",
                  },
                  {
                    validator: (_, value, callback) => {
                      const errors = [];
                      if (value && value.length < 8) {
                        errors.push("At least 8 characters");
                      }
                      if (value && !/[A-Z]/.test(value)) {
                        errors.push("One uppercase letter");
                      }
                      if (value && !/[a-z]/.test(value)) {
                        errors.push("One lowercase letter");
                      }
                      if (value && !/[0-9]/.test(value)) {
                        errors.push("One number");
                      }
                      if (value && !/[\W_]/.test(value)) {
                        errors.push("One special character");
                      }
                      if (errors.length) {
                        callback(errors.join("\n"));
                      } else {
                        callback();
                      }
                    },
                  },
                ],
              })(
                <Input
                  type={passwordVisible ? "text" : "password"}
                  placeholder="Password"
                  value={passwordValue}
                  onChange={handlePasswordChange}
                  ref={passwordRef}
                  suffix={
                    passwordVisible ? (
                      <span style={{ color: "#69B9A4", cursor: "pointer" }}>
                        <EyeInvisibleOutlined
                          onClick={handlePasswordVisibility}
                        />
                      </span>
                    ) : (
                      <EyeTwoTone
                        onClick={handlePasswordVisibility}
                        twoToneColor="#69B9A4"
                      />
                    )
                  }
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator("confirm", {
                rules: [
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
              })(
                <Input
                  type={confirmPasswordVisible ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={confirmPasswordValue}
                  onChange={handleConfirmPasswordChange}
                  ref={confirmPasswordRef}
                  suffix={
                    confirmPasswordValue ? (
                      <span style={{ color: "#69B9A4", cursor: "pointer" }}>
                        <EyeInvisibleOutlined
                          onClick={handleConfirmPasswordVisibility}
                        />
                      </span>
                    ) : (
                      <EyeTwoTone
                        onClick={handleConfirmPasswordVisibility}
                        twoToneColor="#69B9A4"
                      />
                    )
                  }
                />
              )}
            </FormItem>
            <FormItem>
              <Button type="primary" htmlType="submit">
                CREATE
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    </div>
  );
};

const WrappedSetPasswordForm = Form.create()(SetPassword);

export default WrappedSetPasswordForm;
