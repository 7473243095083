import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userSignIn, getUser } from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import { fetchTranslation } from "../appRedux/actions/Setting";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";

const FormItem = Form.Item;

const SignIn = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(({ auth }) => auth.token);
  const role = useSelector(({ auth }) => auth.role);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const passwordRef = useRef(null);

  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
    if (passwordRef.current) {
      const input = passwordRef.current.input;
      input.focus();
      input.setSelectionRange(input.value.length, input.value.length);
    }
  };

  const handlePasswordChange = (e) => {
    setPasswordValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        dispatch(userSignIn(values));
      }
    });
  };

  useEffect(() => {
    if (token !== null && (role === "client" || role === "client_manager")) {
      dispatch(getUser());
      props.history.push("/");
      dispatch(fetchTranslation());
    }
  }, [token, props.history]);

  const { getFieldDecorator } = props.form;
  let bg = require("assets/images/portal_bg.png");

  return (
    <div className="gx-app-login-wrap">
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          filter: "blur(3px)",
          zIndex: -1,
        }}
      ></div>
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div
            className="gx-app-logo-content"
            style={{ backgroundColor: " #00AE97" }}
          >
            <div className="gx-app-logo-wid">
              <h1 style={{ fontSize: 28 }}>
                <b> FINBIT Administrator Login</b>
              </h1>
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              onSubmit={handleSubmit}
              className="gx-signin-form gx-form-row0"
            >
              <FormItem>
                {getFieldDecorator("username", {
                  rules: [
                    {
                      required: true,
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ],
                })(<Input placeholder="Email" />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator("password", {
                  rules: [
                    { required: true, message: "Please input your Password!" },
                  ],
                })(
                  <Input
                    type={passwordVisible ? "text" : "password"}
                    placeholder="Password"
                    value={passwordValue}
                    onChange={handlePasswordChange}
                    ref={passwordRef}
                    suffix={
                      passwordVisible ? (
                        <span style={{ color: "#69B9A4", cursor: "pointer" }}>
                          <EyeInvisibleOutlined
                            onClick={handlePasswordVisibility}
                          />
                        </span>
                      ) : (
                        <EyeTwoTone
                          onClick={handlePasswordVisibility}
                          twoToneColor="#69B9A4"
                        />
                      )
                    }
                  />
                )}
              </FormItem>
              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>

                <Link to="/forgot-password" style={{ marginLeft: 120 }}>
                  <IntlMessages id="app.userAuth.forgotPassword" />
                </Link>
              </FormItem>
            </Form>
          </div>
          <InfoView />
        </div>
      </div>
    </div>
  );
};

const WrappedNormalLoginForm = Form.create()(SignIn);

export default WrappedNormalLoginForm;
